import * as React from "react"
import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout"
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../photos";
import {useCallback, useState} from "react";

const imgWithClick = { cursor: 'pointer' };

const PhotoCustom = ({ index, onClick, photo, margin, direction, top, left, key }) => {
  const imgStyle: { [key: string]: any } = { margin: margin, display: 'block' };
  if (direction === 'column') {
    imgStyle.position = 'absolute';
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = event => {
    onClick(event, { photo, index });
  };

  if (photo.title) {
    return (
      <div
        key={key}
        style={{ position: 'relative' }}
        >
        {/*This is used for sizing*/}
        <img
          key={key}
          style={{...imgStyle, visibility: 'hidden' }}
          {...photo}
          onClick={onClick ? handleClick : null}
        />
        <div
          key={key}
          style={{ position: 'absolute', width: '100%', top: 0}}
        >
          <div style={{ padding: '48px 32px', textAlign: 'left' }}>
            <h3>{photo.title}</h3>
            {photo.subtitle && (<span>{photo.subtitle}</span>)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <img
      key={key}
      style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
      {...photo}
      onClick={onClick ? handleClick : null}
    />
  );
};

interface ExtraPhotoProps {
  title: string
  subtitle: string
}

// class GalleryWithExtraProps extends Gallery<ExtraPhotoProps> {}

function GalleryContent() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery<ExtraPhotoProps> photos={photos} onClick={openLightbox} renderImage={PhotoCustom} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}



const GalleryScreen = () => (
  <Layout>
    <div style={{ textAlign: `center` }}>
      <GalleryContent />
    </div>
  </Layout>
)

export default GalleryScreen
